import {
  action, computed, makeAutoObservable, observable, runInAction, when,
} from 'mobx';
import { ThemeDto, ThemesApi } from '@coretava/api/core-service';
import { AppStore } from '../app';

class ThemeStore {
  @observable theme?: ThemeDto = undefined;

  @observable initialized = false;

  constructor() {
    makeAutoObservable(this);

    void this.initialize();
  }

  @computed
  get api(): ThemesApi {
    return new ThemesApi({
      isJsonMime(): boolean {
        return true;
      },
      basePath: process.env.BASE_API_URL,
    });
  }

  @action
  public async initialize(): Promise<void> {
    await when(() => !!AppStore.app);

    if (AppStore.app) {
      const response = await this.api.themesControllerGetByApp(AppStore.app.id);

      if (response.data.theme) {
        runInAction(() => {
          this.theme = response.data.theme;
          this.initialized = true;
        });
      }
    }
  }
}

export default new ThemeStore();
