import {
  action, computed, makeAutoObservable, observable, runInAction, when,
} from 'mobx';
import { AppDto, AppDtoTypesEnum, AppsApi } from '@coretava/api/core-service';
import { I18n } from '@gamiphy/react-i18n';
import { getLocale } from '@gamiphy/utils/locale';
import { getCurrencyFormatter, Languages } from '@gamiphy/i18n';

class AppStore {
  @observable app?: AppDto = undefined;

  @observable initialized = false;

  constructor() {
    makeAutoObservable(this);

    void this.initialize();
    void this.initializeI18n();
    void this.initCurrency();
  }

  @computed
  get api(): AppsApi {
    return new AppsApi({
      isJsonMime(): boolean {
        return true;
      },
      basePath: process.env.BASE_API_URL,
    });
  }

  @computed get isOmniApp(): boolean {
    return [AppDtoTypesEnum.CoreLoyalty, AppDtoTypesEnum.Fisico].every((type) => this.app?.types.includes(type));
  }

  @computed get isOfflineApp(): boolean {
    return !!this.app?.types.includes(AppDtoTypesEnum.Fisico);
  }

  @computed get isOfflineOnlyApp(): boolean {
    return !!this.app?.types.includes(AppDtoTypesEnum.Fisico) && !this.isOnlineApp;
  }

  @computed get isOnlineApp(): boolean {
    return !!this.app?.types.includes(AppDtoTypesEnum.CoreLoyalty);
  }

  @computed get isOnlineOnlyApp(): boolean {
    return !!this.app?.types.includes(AppDtoTypesEnum.CoreLoyalty) && !this.isOfflineApp;
  }

  @action
  public async initialize(): Promise<void> {
    // eslint-disable-next-line no-underscore-dangle
    const response = await this.api.appsControllerGet(window.__APP_ID__);

    runInAction(() => {
      this.app = response.data.app;
      this.initialized = true;
    });
  }

  private async initCurrency() {
    await when(() => this.initialized);
    window.GAMIPHY_CURRENCY_CODE = this.app?.currency;
  }

  private async initializeI18n() {
    await when(() => this.initialized);
    await I18n.initialize({
      language: this.app?.language as Languages,
      formatters: getCurrencyFormatter(getLocale(), this.app?.currency),
      detectionOrder: ['providedLanguage', 'navigator'],
    });
  }
}

export default new AppStore();
